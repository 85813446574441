import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Image,
  Tab,
  Tabs,
  User,
} from "@nextui-org/react";
import { MoreHeaderComponent } from "./components/header-component";
import FeatherIcon from "feather-icons-react";
import { MoreTileComponenets } from "./components/more-tile-component";
import { useLoaderData } from "react-router-dom";
import {Customer} from '@medusajs/medusa';
import { capitalize, first } from "lodash";
import { FormImage, LOCAL_STORAGE_ACCESS_TOKEN_KEY, LOCAL_STORAGE_DEMO_MODE_KEY } from "../../../../utils";
import { useContext } from "react";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../..";
import { BASE_URL } from "../../../../utils/constants";

export const MoreFragment = () => {
  const customer: Customer = useLoaderData() as Customer;
  
  const { setLoading } = useContext(LoadingContext);
  const redeemReferralsAction = async () => {
    setLoading!(true);

    // create the deposit and dispose
    fetch(`${BASE_URL}store/wallet/redeem-referrals`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
        demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY) ? 'yes':'no'
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message, errors }: { message: string, errors: string[]  } = await response.json();
          throw new Error(message ?? first([errors]));
        }

        // const { trade }: { deposit: Deposit } = await response.json();
        // close dialog

        toast.warning("Your referral balance has been moved to your main balance");

      })
      .catch((err) => {
        // show toaster
        toast.error(err.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className="mt-2 mb-2">
        <MoreHeaderComponent />
      </div>

      <div className="flex justify-between w-full flex-rows px-4 mt-2">
        <div className="flex flex-col items-start w-full ">
          
        <User
          name={`${customer.last_name??''} ${customer.first_name??''}`.trim()}
          description={capitalize((customer as any).level)}
          avatarProps={{
            src: 
            first(
              (customer.metadata?.avatar as FormImage[] | undefined) ?? []
            )?.url,
          }}
        />

          <Card className="py-4 mt-4 w-full" style={{ width: "100%" }}>
            <CardHeader className="pb-0 pt-2 px-4 flex-rows justify-center items-center">
              <h4 className="font-bold text-large mr-2">{(customer as any).referralCode}</h4>
              <Button
                isIconOnly
                size="sm"
                color="warning"
                variant="faded"
                aria-label="Copy referral code"
                
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    `Register on autrid with my referral code\n\nhttps://app.autrid.com/register?code=${(customer as any).referralCode}
                    `

                  );
                  toast.success(`Copied`)
                }}
              >
                <FeatherIcon icon="copy" size={"16px"} />
              </Button>
            </CardHeader>
            <CardBody className="overflow-visible py-2 flex-rows justify-center items-center  px-6">
              <small className="text-default-500">
                Refer your friends and get 20% of their first deposit
              </small>
              <small className="text-default-500">
                You've referred {(customer as any).referredUsersCount} users
              </small>

              <div
                className="w-full bg-slate-200 mt-4"
                style={{ height: 1 }}
              ></div>

              <div className="flex flex-rows w-full justify-between mt-4 px-2">
                <div className="flex flex-col items-start">
                  <h4 className="text-small font-semibold leading-none text-default-600">
                    Referral Balance
                  </h4>

                  <p className="text-lg font-semibold">
                    {new Intl.NumberFormat("en-US", {
                      currency: "NGN",
                      style: "currency",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format((customer as any).referralAccountBalance)}
                  </p>
                </div>
                <Button color="warning" onClick={() => redeemReferralsAction()}>Redeem</Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="mt-8 w-full">
        <MoreTileComponenets />
      </div>
    </div>
  );
};
