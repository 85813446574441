import React from "react";

import { Customer } from "@medusajs/medusa";
// import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { createChart } from "lightweight-charts";
import { Spinner, Tab, Tabs } from "@nextui-org/react";
import { Asset } from "../../../../../lookups/assets";
import { BASE_URL } from "../../../../../utils/constants";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_PREFER_LINE_CHART_KEY,
} from "../../../../../utils";
import { debounce, first, last, reverse, sortBy, uniqBy } from "lodash";
import { DateTime } from "luxon";

type Tick = {
  open: number;
  close: number;
  high: number;
  low: number;
  time: number;
};
type RawTick = {
  price: number;
  time: number;
};

export class Chart extends React.Component<
  {
    asset: Asset;
  },
  {
    useLineChart: boolean;
    currentPrice?: number;
    percentageChange?: number;
    isLoadingChart?: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentPrice: this.props.asset.currentPrice,
      useLineChart: localStorage.getItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY)
        ? true
        : false,
      isLoadingChart: false,
    };

    this.canvasRef = React.createRef();
  }
  isDrawn: boolean = false;
  canvasRef: any;
  activeSeries: any;
  activeChart: any;

  componentDidMount(): void {
    if (this.isDrawn) return;
    this.isDrawn = true;
    //drawer

    setTimeout(() => {
      this.refresh();
    }, 1200);
  }

  noMoreDataHits = 0;
  startDate = DateTime.now().toMillis() / 1000;
  range?: string;

  async fetchHistoricalData() {
    if (this.state.isLoadingChart) return;
    if(isNaN(Number(this.startDate))) return;

    this.setState({
      ...this.state,
      isLoadingChart: true,
    });
    const qs = [
      `${!this.state.useLineChart?`interval=${(()=>this.range)() ?? "5m"}`:''}`,
      `${this.state.useLineChart?`chartMode=line`:`chartMode=candle`}`,
      `created_before=${(() => this.startDate)()}`

    ].filter((x) => x.length>0).join('&');
    fetch(
      `${BASE_URL}store/assets/chart/${this.props.asset!.symbol}?${qs}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        },
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          const { message, errors }: { message: string; errors: string[] } =
            await response.json();
          throw new Error(message ?? first([errors]));
        }

        const {
          ticks,
          rawTicks,
          nextPage,
          percentageChange
        }: { ticks: Tick[]; rawTicks: RawTick[]; nextPage: string, percentageChange: number } =
          await response.json();

        this.startDate =  parseInt(nextPage);
        

        if (rawTicks.length ==  0|| ticks?.length == 0) {
          this.noMoreDataHits += 1;
          this.setState({
            ...this.state,
            isLoadingChart: false,
          });
          return this.fetchHistoricalData();
        } else {
          this.noMoreDataHits = 0;
        }

        if (localStorage.getItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY)) {
          rawTicks.forEach((e) => {
            e.price = Number(e.price);
            (e as any).value = e.price;
            e.time = Number(e.time);
          });

          this.activeSeries.setData(
            uniqBy(
              sortBy(
                [...this.activeSeries.data(), ...(rawTicks as any)],
                (x) => x.time
              ),
              "time"
            )
          );
        } else {
          ticks.forEach((e) => {
            e.time = Number(e.time);
            e.open = Number(e.open);
            e.high = Number(e.high);
            e.low = Number(e.low);
            e.close = Number(e.close);
          });

          this.activeSeries.setData(
            uniqBy(
              sortBy(
                [...this.activeSeries.data(), ...(ticks as any)],
                (x) => x.time
              ),
              "time"
            )
          );
          // this.activeChart.timeScale().fitContent();
        }

        this.setState({
          ...this.state,
          percentageChange,
          currentPrice: last(ticks)?.close,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message ?? "An error occured");
      })
      .finally(() => {
        this.setState({
          ...this.state,
          isLoadingChart: false,
        });
      });
  }

  revenueFlowChart: any;
  async refresh(range: string = "5m") {
    if (this.range == range) return;
    this.range = range;

    this.noMoreDataHits = 0;
    this.startDate = DateTime.now().toMillis() / 1000;

    this.setState({ ...this.state });
    const chartOptions = {
      layout: {
        textColor: "black",
        background: { type: "solid", color: "white" },
      },
      timeScale: {
        tickMarkFormatter: (time: any) =>
          DateTime.fromMillis(time * 1000).toLocaleString(DateTime.TIME_SIMPLE),
      },
      localization: {
        dateFormat: "",
        timeFormatter: (time: any) =>
          DateTime.fromMillis(time * 1000).toLocaleString(
            DateTime.DATETIME_SHORT
          ),
      },
    };

    let willAttachListener = true;
    if (this.activeChart) willAttachListener = false;

    this.activeChart ??= createChart(
      this.canvasRef.current,
      chartOptions as any
    );

    if (this.activeSeries) this.activeChart.removeSeries(this.activeSeries!);

    this.activeSeries = this.state.useLineChart
      ? this.activeChart.addLineSeries({ color: "#2962FF" })
      : this.activeChart.addCandlestickSeries({
          upColor: "#26a69a",
          downColor: "#ef5350",
          borderVisible: false,
          wickUpColor: "#26a69a",
          wickDownColor: "#ef5350",
        });

    if (willAttachListener) {
      const timescale = this.activeChart.timeScale();

      const fireFetch = debounce(() => {
        this.fetchHistoricalData();
      }, 300);

      timescale.subscribeVisibleLogicalRangeChange(
        (newVisibleLogicalRange: any) => {
          const barsInfo = this.activeSeries.barsInLogicalRange(
            newVisibleLogicalRange
          );
          // ? we've seen the last bar and the current startDate is the next timestamp to drive backwards from
          if (
            barsInfo !== null &&
            barsInfo.barsBefore <= 0 &&
            this.noMoreDataHits <= 3
          ) {
            console.error(this.startDate);
            fireFetch();
          }
        }
      );
    }

    // fetch correct chart
    this.fetchHistoricalData();
  }

  render() {
    return (
      <>
        <div className="flex  px-4 pt-4 pb-4 items-center">
          <div className="flex flex-col">
            <p className="text-lg font-semibold">
              {new Intl.NumberFormat("en-US", {
                currency: "NGN",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(Number(this.state.currentPrice ?? 0))}
            </p>
            <p
              className={`text-sm font-bold ${
                Number(this.state.percentageChange ?? 0) <= 0
                  ? "text-red-600"
                  : "text-green-500"
              }`}
            >
              {Number(this.state.percentageChange)?.toFixed(2)}%
            </p>
          </div>

          <div className="grow "></div>

          <div className="flex flex-row">
            {this.state.isLoadingChart && (
              <Spinner size="sm"  />
            )}
            {!this.state.useLineChart && <Tabs
              onSelectionChange={(k) => this.refresh(k as any)}
              key={"5m"}
              size={"sm"}
              className="ml-4"
              aria-label="Tabs sizes"
            >
              {/* <Tab key="2m" title="2m" /> */}
              <Tab key="5m" title="5m" />
              <Tab key="24h" title="1D" />
              <Tab key="168h" title="1W" />
              <Tab key="672h" title="1M" />
            </Tabs>}
          </div>
        </div>
        <div
          className="w-full"
          style={{ height: "300px" }}
          ref={this.canvasRef}
        ></div>
      </>
    );
  }
}
