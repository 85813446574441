import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { LoadingContext } from "../../..";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { isNil } from "lodash";
import { toast } from "react-toastify";

export const ResetPasswordDialog = (props: {
    
  token?: string;
  email?: string;
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const schema = yup
    .object({
      new_password: yup.string().required(),
      confirm_password: yup.string().equals([yup.ref('new_password')], "passwords don't match").required()
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{
    new_password: string;
    confirm_password: string;
  }>({
    defaultValues: {} as any,
    resolver: yupResolver(schema as any),
  });

  const { setLoading } = useContext(LoadingContext);
  const [state, setState] = useState({
    registerSuccessful: false,
    obscurePass: true,
  });
  const { client: medusaClient } = useMedusa();

  const resetPassword = async (data: {
    new_password: string;
    confirm_password: string;
  }) => {
    // console.error(data);
    setLoading!(true);
    medusaClient.customers
      .resetPassword({token: props.token!, email: props.email!, password: data.new_password})
      .then((res) => {
        toast.success("Password reset successfully");
        props.closeModal();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? "An error occured");
      })
      .finally(() => {
        reset();
        setLoading!(false);
      });;
  };

  return (
    <>
      <Modal
        size={"sm"}
        isOpen={props.isOpen}
        onClose={() => props.closeModal()}
      >
                <form onSubmit={handleSubmit(resetPassword)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Reset your password
              </ModalHeader>
              <ModalBody>
                  <div className="mb-6">
                    <Input
                      label="Password"
                      fullWidth={true}
                      placeholder="Enter a password"
                      errorMessage={errors.new_password?.message}
                      isInvalid={!isNil(errors.new_password?.message)}
                      color={
                        errors.new_password?.message ? "danger" : undefined
                      }
                      endContent={
                        <button
                          className="focus:outline-none"
                          type="button"
                          onClick={() =>
                            setState({
                              ...state,
                              obscurePass: !state.obscurePass,
                            })
                          }
                        >
                          {state.obscurePass ? (
                            <FeatherIcon icon="eye-off"></FeatherIcon>
                          ) : (
                            <FeatherIcon icon="eye"></FeatherIcon>
                          )}
                        </button>
                      }
                      type={state.obscurePass ? "password" : "text"}
                      {...register("new_password", {
                        required: true,
                        minLength: 4,
                      })}
                    />
                  </div>
                  <div className="mb-6">
                    <Input
                      label="Confirm Password"
                      fullWidth={true}
                      placeholder="Confirm your password"
                      errorMessage={errors.confirm_password?.message}
                      isInvalid={!isNil(errors.confirm_password?.message)}
                      color={
                        errors.confirm_password?.message ? "danger" : undefined
                      }
                      endContent={
                        <button
                          className="focus:outline-none"
                          type="button"
                          onClick={() =>
                            setState({
                              ...state,
                              obscurePass: !state.obscurePass,
                            })
                          }
                        >
                          {state.obscurePass ? (
                            <FeatherIcon icon="eye-off"></FeatherIcon>
                          ) : (
                            <FeatherIcon icon="eye"></FeatherIcon>
                          )}
                        </button>
                      }
                      type={state.obscurePass ? "password" : "text"}
                      {...register("confirm_password", {
                        required: true,
                        minLength: 4,
                      })}
                    />
                  </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Return to login
                </Button>
                <Button color="warning" type="submit">
                  Continue
                </Button>
              </ModalFooter>
            </>
          )}
                </ModalContent>
                </form>
      </Modal>
    </>
  );
};
