import { createContext } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import { App } from "./App";
import "./index.css";
import LoginPage from "./pages/authentication/login";
import Root from "./pages/root";
import reportWebVitals from "./reportWebVitals";
import CreateAccountPage from "./pages/authentication/register";
import { RouteGuard } from "react-guardian";
import { loggedInOnlyPolicy } from "./guards";
import HomePage from "./pages/home";
import { MoreFragment } from "./pages/home/fragments/more/more-fragment";
import { PrivacyPolicyComponent } from "./pages/home/fragments/more/privacy-policy-fragment";
import { TermsOfServiceComponent } from "./pages/home/fragments/more/terms-of-service";
import { BASE_URL } from "./utils/constants";
import Medusa from "@medusajs/medusa-js";
import {
  PersonalInformationFragmentLoader,
  ProfileUpdateFragment,
} from "./pages/home/fragments/more/profile-update-fragment";
import { CustomerDetailFragment } from "./pages/home/fragments/customers/customer-detail-fragment";
import { Store } from "@medusajs/medusa";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "./utils";
import { AssetDetaulFragmentLoader, AssetsFragment } from "./pages/home/fragments/assets/assets-fragment";
import { first } from "lodash";
import { NotificationsListFragment } from "./pages/home/fragments/notifications/notifications-fragmnt";
import { useLogoutDeleteSession } from "./lookups/customer-trades";
import { toast } from "react-toastify";

export const medusa = new Medusa({
  baseUrl: BASE_URL,
  maxRetries: 2,
});

const StoreConfigurationLoader = async (params: Record<string, any>) => {
  const req = await fetch(`${BASE_URL}store/domain/info`);

  const { logout } = useLogoutDeleteSession();

  if (!req.ok) {
    const { message, errors }: { message: string; errors: string[] } =
      await req.json();
    throw new Error(message ?? first([errors]));
  }
  if (req.status == 401) {
    // logout
    logout()
      .then(() => {
        localStorage.clear();
        window.location.pathname = "/";
      })
      .catch((e) => {
        toast.error("Failed to log you out");
      });
  }

  const { store }: { store: Store } = await req.json();

  return { store };
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root />}
      id="root"
      loader={StoreConfigurationLoader}
    >
      <Route
        path="home"
        element={
          <RouteGuard policies={[loggedInOnlyPolicy]}>
            <HomePage />
          </RouteGuard>
        }
        children={[
          <Route index path="trade" 
          
          loader={AssetDetaulFragmentLoader}
          element={<AssetsFragment />} />,
          <Route
            path="wallet"
            loader={PersonalInformationFragmentLoader}
            element={<CustomerDetailFragment />}
          />,
          <Route
            path="more"
            loader={PersonalInformationFragmentLoader}
            element={<MoreFragment />}
          />,
          <Route
            path="notifications"
            element={<NotificationsListFragment />}
          />,
          <Route
            path="update-profile"
            loader={PersonalInformationFragmentLoader}
            element={<ProfileUpdateFragment />}
          />,
          <Route
            path="/home/"
            element={<Navigate to="/home/trade" replace={true} />}
          />,
          <Route path="/home/*" element={<Navigate to="/home/trade" />} />,
        ]}
      />
      <Route
        path="public"
        element={<Outlet />}
        children={[
          <Route
            index
            path="privacy-policy"
            element={<PrivacyPolicyComponent />}
          />,
          <Route
            path="terms-of-service"
            element={<TermsOfServiceComponent />}
          />,

          <Route
            path="/public/"
            element={<Navigate to="/public/privacy-policy" replace={true} />}
          />,
          <Route path="/public/*" element={<Navigate to="/public/privacy-policy" />} />,
        ]}
      />
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<CreateAccountPage />} />
      <Route path="/" element={<Navigate to="/login" replace={true} />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const LoadingContext = createContext<{
  loading: boolean;
  setLoading?: (loading: boolean) => void;
}>({ loading: false });

export const DemoModeContext = createContext<{
  demoMode: boolean;
  setDemoMode?: (demoMode: boolean) => void;
}>({ demoMode: false });

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
