import {
  Card,
  CardHeader,
  Divider,
  CardBody,
  CardFooter,
  Tabs,
  Tab,
  Button,
  Select,
  SelectItem,
  Input,
  Avatar,
  Slider,
} from "@nextui-org/react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Asset } from "../../../../../lookups/assets";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingContext } from "../../../../..";
import { first, isNil } from "lodash";
import { BASE_URL } from "../../../../../utils/constants";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "../../../../../utils";
import { toast } from "react-toastify";
import { CustomerOpenTradesListComponent } from "./customer-open-trades-list-component";
import { useLogoutDeleteSession } from "../../../../../lookups/customer-trades";

export const AssetTradeComponent = (props: { asset: Asset }) => {
  const [isUpdateAssetDialogOpen, setIsUpdateAssetDialogOpen] = useState(false);
  const [asset, setAsset] = useState<Asset | undefined>(props.asset);
  const [balances, setBalances] = useState<{
    assetBalance?: number;
    ngnBalance?: number;
  }>();
  const { logout } = useLogoutDeleteSession();

  useEffect(() => {
    const loadTradeableBalance = async () => {
      try {
        const req = await fetch(
          `${BASE_URL}store/wallet/tradeable-balance/${props.asset.symbol}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                LOCAL_STORAGE_ACCESS_TOKEN_KEY
              )}`,
              demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY)
                ? "yes"
                : "no",
            },
          }
        );

        if (!req.ok) {
          const { message }: { message: string; errors: string[] } =
            await req.json();
          throw new Error(message);
        }
        if (req.status == 401) {
          // logout
    logout()
    .then(() => {
      localStorage.clear();
      window.location.pathname = "/";
    })
    .catch((e) => {
      toast.error("Failed to log you out");
    });
        }
        const {
          assetBalance,
          ngnBalance,
        }: { assetBalance: string; ngnBalance: string } = await req.json();

        setBalances({
          assetBalance: Number(assetBalance),
          ngnBalance: Number(ngnBalance),
        });
      } catch (error: any) {
        if ((error?.response as any)?.status == 401) {
          
    logout()
    .then(() => {
      localStorage.clear();
      window.location.pathname = "/";
    })
    .catch((e) => {
      toast.error("Failed to log you out");
    });
        } else if ((error as any).name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("There was an error with the fetch operation:", error);
        }
      }
    };

    loadTradeableBalance();
  }, []);

  return (
    <>
      <Tabs
        key={"buy"}
        size={"sm"}
        aria-label="Customer Detail Tabs"
        className="mb-4 self-center"
      >
        <Tab key="buy" title="Buy" className="w-full">
          {balances && <TradeBuyCard key={`balance_${balances.ngnBalance}`} asset={props.asset} balances={balances} setBalances={setBalances}/>}
        </Tab>
        <Tab key="sell" title="Sell" className="w-full">
          {balances && <TradeSellCard asset={props.asset}  key={`balance_${balances.ngnBalance}`}  balances={balances} setBalances={setBalances}/>}
        </Tab>
        <Tab key="open_trades" title="Trades" className="w-full">
          <CustomerOpenTradesListComponent symbol={props.asset.symbol} />
        </Tab>
      </Tabs>
    </>
  );
};

const TradeBuyCard = (props: { asset: Asset, balances: {ngnBalance?: number}, setBalances: (balances: {assetBalance: number, ngnBalance: number})=>void }) => {
  const max = Math.floor(((props.balances.ngnBalance!)+ Number.EPSILON) * 100) / 100
  const schema = yup
    .object({
      amount: yup.number().required().min(1).max(max),
      limit: yup.number().optional(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<{
    amount: number;
    limit: number;
  }>({
    defaultValues: { amount: 1 } as any,
    resolver: yupResolver(schema as any),
  });

  const createBuyOrderAction = async (data: {
    amount: number;
    limit: number;
  }) => {
    setLoading!(true);

    // create the deposit and dispose
    fetch(`${BASE_URL}store/wallet/buy`, {
      method: "POST",
      body: JSON.stringify({
        amount: data.amount,
        ...(orderType == "limit" ? { limit: data.limit } : {}),
        symbol: props.asset.symbol,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
        demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY) ? "yes" : "no",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message, errors }: { message: string; errors: string[] } =
            await response.json();
          throw new Error(message ?? first([errors]));
        }

        const { assetBalance, ngnBalance }: {  assetBalance: string, ngnBalance: string } = await response.json();
        // close dialog
        props.setBalances({
          assetBalance: Number(assetBalance),
          ngnBalance: Number(ngnBalance),
        })

        toast.warning("Order placed successfully");

        reset();
      })
      .catch((err) => {
        // show toaster
        toast.error(err.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  const { setLoading } = useContext(LoadingContext);

  const [orderType, setOrderType] = useState<"market" | "limit">("market");
  // amount, order type
  return (
    <>
      <form onSubmit={handleSubmit(createBuyOrderAction)}>
        <div
          className={`${
            orderType == "limit"
              ? "grid grid-cols-2 gap-x-4"
              : "grid grid-cols-1"
          }`}
        >
          <Select
            label="Order Type"
            placeholder="Select Order Type"
            defaultSelectedKeys={[orderType]}
            onChange={(ev) => {
              setOrderType(ev.target.value as any);
            }}
            selectionMode="single"
          >
            <SelectItem key={"market"} className="capitalize">
              Market
            </SelectItem>
            <SelectItem key={"limit"} className="capitalize">
              Limit
            </SelectItem>
          </Select>

          {orderType == "limit" && (
            <Input
              type="text"
              label="Limit"
              errorMessage={errors.limit?.message}
              isInvalid={!isNil(errors.limit?.message)}
              color={errors.limit?.message ? "danger" : undefined}
              {...register("limit", { valueAsNumber: true })}
              startContent={<h4 className="text-sm font-bold">NGN</h4>}
            />
          )}
        </div>

        <Input
          type="text"
          className="mt-4"
          label="Amount"
          errorMessage={errors.amount?.message}
          isInvalid={!isNil(errors.amount?.message)}
          color={errors.amount?.message ? "danger" : undefined}
          {...register("amount", { required: true, valueAsNumber: true })}
          startContent={<h4 className="text-sm font-bold">NGN</h4>}
        />

<Slider 
      label="Select volume" 
      color="foreground"
      size="sm"
      step={0.01} 
      minValue={0.01}
      maxValue={1}
      // {...register("amount", { required: true, valueAsNumber: true })}
      marks={[
        {
          value: 0.25,
          label: "25%",
        },
        {
          value: 0.5,
          label: "50%",
        },
        {
          value: 0.75,
          label: "75%",
        },
        
      ]}
      formatOptions={{style: "percent"}}
      onChange={(value) => {
        setValue('amount', 
          Math.floor(((value as any[0]*props.balances.ngnBalance!)+ Number.EPSILON) * 100) / 100

          )}}
      className="mt-4"
    />

        <Button type="submit" fullWidth={true} color="warning" className="mt-4">
          Submit
        </Button>
      </form>
    </>
  );
};

const TradeSellCard = (props: { asset: Asset , balances: {assetBalance?: number}, setBalances: (balances: {assetBalance: number, ngnBalance: number})=>void }) => {
  const max = Math.floor(((props.balances.assetBalance!)+ Number.EPSILON) * 100) / 100;
  const schema = yup
    .object({
      amount: yup.number().positive().required().max(max),
      limit: yup.number().optional(),
      tp: yup.number().optional(),
      sl: yup
        .number()
        .optional()
        .lessThan(yup.ref("tp"), "TP cannot be less than SL"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{
    amount: number;
    limit: number;
    tp: number;
    sl: number;
  }>({
    defaultValues: { amount: 1 } as any,
    resolver: yupResolver(schema as any),
  });

  const { setLoading } = useContext(LoadingContext);

  const createSellOrderAction = async (data: {
    amount: number;
    limit: number;
    tp: number;
    sl: number;
  }) => {
    setLoading!(true);

    // create the deposit and dispose
    fetch(`${BASE_URL}store/wallet/sell`, {
      method: "POST",
      body: JSON.stringify({
        amount: data.amount,
        ...(orderType == "limit"
          ? { limit: data.limit}
          : {}),
        ...(orderType == "tp_sl"
          ? {limit: data.limit, tp: data.tp, sl: data.sl }
          : {}),
        symbol: props.asset.symbol,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
        demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY) ? "yes" : "no",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message, errors }: { message: string; errors: string[] } =
            await response.json();
          throw new Error(message ?? first([errors]));
        }

        const { assetBalance, ngnBalance }: {  assetBalance: string, ngnBalance: string } = await response.json();
        // close dialog
        props.setBalances({
          assetBalance: Number(assetBalance),
          ngnBalance: Number(ngnBalance),
        })


        toast.warning("Order placed successfully");

        reset();
      })
      .catch((err) => {
        // show toaster
        toast.error(err.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  const [orderType, setOrderType] = useState<"market" | "limit"|"tp_sl">("market");
  // amount, order type
  return (
    <>
      <form onSubmit={handleSubmit(createSellOrderAction)}>
        <div
          className={`${
            orderType == "limit"||orderType == "tp_sl"
              ? "grid grid-cols-2 gap-x-4"
              : "grid grid-cols-1"
          }`}
        >
          <Select
            label="Order Type"
            placeholder="Select Order Type"
            defaultSelectedKeys={[orderType]}
            onChange={(ev) => {
              setOrderType(ev.target.value as any);
            }}
            selectionMode="single"
          >
            <SelectItem key={"market"} className="capitalize">
              Market
            </SelectItem>
            <SelectItem key={"limit"} className="capitalize">
              Limit
            </SelectItem>
            <SelectItem key={"tp_sl"} className="capitalize">
              TP/SL
            </SelectItem>
          </Select>

          {(orderType == "limit"|| orderType == "tp_sl") && (
            <Input
              type="text"
              label="Limit"
              errorMessage={errors.limit?.message}
              isInvalid={!isNil(errors.limit?.message)}
              color={errors.limit?.message ? "danger" : undefined}
              {...register("limit", { valueAsNumber: true })}
              startContent={<h4 className="text-sm font-bold">NGN</h4>}
            />
          )}
        </div>

        <Input
          type="text"
          className="mt-4"
          label="Amount"
          errorMessage={errors.amount?.message}
          isInvalid={!isNil(errors.amount?.message)}
          color={errors.amount?.message ? "danger" : undefined}
          {...register("amount", { valueAsNumber: true, required: true })}
          endContent={
            props.asset.metadata.avatar &&
            props.asset.metadata.avatar[0] && (
              <div>
                <Avatar size="sm" src={props.asset.metadata.avatar[0].url} />
              </div>
            )
          }
        />
        <Slider 
              label="Select volume" 
              color="foreground"
              size="sm"
              step={0.01} 
              minValue={0.01}
              maxValue={1}
              // {...register("amount", { required: true, valueAsNumber: true })}
              marks={[
                {
                  value: 0.25,
                  label: "25%",
                },
                {
                  value: 0.5,
                  label: "50%",
                },
                {
                  value: 0.75,
                  label: "75%",
                },
                
              ]}
              formatOptions={{style: "percent"}}
              onChange={(value) => {
                setValue('amount',
                  
          Math.floor(((value as any[0]*props.balances.assetBalance!)+ Number.EPSILON) * 100) / 100
                  )}}
              className="mt-4"
            />

        {orderType == "tp_sl" && (
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="text"
              className="mt-4"
              label="TP"
              errorMessage={errors.tp?.message}
              isInvalid={!isNil(errors.tp?.message)}
              color={errors.tp?.message ? "danger" : undefined}
              {...register("tp", { valueAsNumber: true })}
              startContent={<h4 className="text-sm font-bold">NGN</h4>}
            />
            <Input
              type="text"
              className="mt-4"
              label="SL"
              errorMessage={errors.sl?.message}
              isInvalid={!isNil(errors.sl?.message)}
              color={errors.sl?.message ? "danger" : undefined}
              {...register("sl", { valueAsNumber: true })}
              startContent={<h4 className="text-sm font-bold">NGN</h4>}
            />
          </div>
        )}

        <Button type="submit" fullWidth={true} color="warning" className="mt-4">
          Submit
        </Button>
      </form>
    </>
  );
};
