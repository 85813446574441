import { Link } from "@nextui-org/react";
import { MoreHeaderComponent } from "./components/header-component";

export const PrivacyPolicyComponent = () => {
  return (
    <div className="flex flex-col items-start">
      <div style={{ marginTop: "56px" }}></div>

      <div className="mt-2 mb-2">
        <MoreHeaderComponent  title="Privacy Policy"/>
      </div>

      <div className="p-8">
        <h1 className="text-lg font-bold">Privacy Policy</h1>
        <p>
          <strong>Effective Date:</strong> 1st January, 2024
        </p>

        <br/><h2><strong>1. Introduction</strong></h2>
        <p>
          Welcome to Autrid, a trading platform operated by the Autrid Group. We
          are committed to protecting your privacy and ensuring that your
          personal information is handled in a safe and responsible manner. This
          Privacy Policy explains how we collect, use, and protect your personal
          data.
        </p>

        <br/><h2><strong>2. Personal Data We Collect</strong></h2>
        <p>We collect the following types of personal data from users:</p>
        <ul>
          <li>Names</li>
          <li>Email addresses</li>
          <li>Bank details (sensitive information)</li>
          <li>Phone numbers</li>
        </ul>

        <br/><h2><strong>3. How We Collect Personal Data</strong></h2>
        <p>
          Personal data is submitted by customers through forms for updating
          their profiles and registering on our platform.
        </p>

        <br/><h2><strong>4. Purpose of Data Collection</strong></h2>
        <p>We collect personal data for the following purposes:</p>
        <ul>
          <li>
            <strong>Names:</strong> For personalization of user experience.
          </li>
          <li>
            <strong>Email addresses:</strong> For app functionality, including
            notifications and account-related communications.
          </li>
          <li>
            <strong>Bank details:</strong> For settlement after trades during
            withdrawal.
          </li>
          <li>
            <strong>Phone numbers:</strong> For account verification and
            communication.
          </li>
        </ul>

        <br/><h2><strong>5. Data Sharing</strong></h2>
        <p>We do not share personal data with any third parties.</p>

        <br/><h2><strong>6. User Rights</strong></h2>
        <p>Users have the right to:</p>
        <ul>
          <li>
            Access and update their personal information at their discretion.
          </li>
          <li>Contact our support team to handle account deletion.</li>
        </ul>

        <br/><h2><strong>7. Data Security</strong></h2>
        <p>
          We implement standard and reasonable measures to protect your personal
          data against unauthorized access, alteration, disclosure, or
          destruction.
        </p>

        <br/><h2><strong>8. Cookies and Tracking Technologies</strong></h2>
        <p>
          We use cookies only for maintaining user sessions. We do not use
          cookies for tracking users.
        </p>

        <br/><h2><strong>9. International Data Transfers</strong></h2>
        <p>We do not transfer personal data internationally.</p>

        <br/><h2><strong>10. Children's Privacy</strong></h2>
        <p>
          All users of the application must be above 18 years old. We do not
          knowingly collect data from children under the age of 18.
        </p>

        <br/><h2><strong>11. Contact Information</strong></h2>
        <p>
          If you have any questions or concerns about our privacy practices,
          please contact us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <Link href="mailto:support@autrid.com" className="">support@autrid.com</Link>
        </p>

        <br/><h2><strong>12. Policy Updates</strong></h2>
        <p>
          Updates to this Privacy Policy will be communicated via email. Please
          check your email regularly to stay informed about any changes.
        </p>

        <br/><h2><strong>13. Legal Compliance</strong></h2>
        <p>We comply with the General Data Protection Regulation (GDPR).</p>

        <br/><h2><strong>14. Changes to This Policy</strong></h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be effective immediately upon posting the updated policy on our
          website. Your continued use of our services after any changes to this
          policy constitutes your acceptance of the updated terms.
        </p>

        <br/><h2><strong>15. Governing Law</strong></h2>
        <p>This Privacy Policy is governed by the laws of Nigeria.</p>

        <p>
          By using our platform, you acknowledge that you have read and
          understood this Privacy Policy and agree to our collection, use, and
          disclosure of your personal data as described herein.
        </p>
      </div>
    </div>
  );
};
