import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { MedusaProvider } from "medusa-react";
import { RouterProvider } from "react-router-dom";
import { DemoModeContext, LoadingContext, router } from ".";
import Medusa from "@medusajs/medusa-js";
import { QueryClient } from "@tanstack/react-query";
import { BASE_URL } from "./utils/constants";
import { NextUIProvider } from "@nextui-org/react";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const medusa = new Medusa({
  baseUrl: BASE_URL,
  maxRetries: 2,
});

const queryClient = new QueryClient();

export const App = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [demoMode, setDemoMode] = useState<boolean>(false);
  

  return (
    <>
    <ToastContainer />
      <link
        rel="stylesheet"
        href="/css/font-awesome-all.min.css"
        type="text/css"
      />

      {/* <React.StrictMode> */}
        <NextUIProvider>
          <MedusaProvider
            medusaClient={medusa}
            queryClientProviderProps={{ client: queryClient }}
            baseUrl={BASE_URL}
          >
            <LoadingContext.Provider
              value={{ loading: loading, setLoading: setLoading }}
            >
            <DemoModeContext.Provider
              value={{ demoMode: demoMode, setDemoMode: setDemoMode }}
            >
              <RouterProvider router={router} />
            </DemoModeContext.Provider>
            </LoadingContext.Provider>
          </MedusaProvider>
        </NextUIProvider>
      {/* </React.StrictMode> */}
    </>
  );
};
