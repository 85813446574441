// Import React FilePond

import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader
} from "@nextui-org/react";
import { first, isNil } from "lodash";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../../..";
import {
    LOCAL_STORAGE_ACCESS_TOKEN_KEY
} from "../../../../../utils";
import { BASE_URL } from "../../../../../utils/constants";

export type RequestWithdrawalFormType = {
  amount: string;
};

export type Withdrawal = {
    id: string;
  amount: number;
  created_at: string;
  status: 
  'pending'|
'approved'|
'denied'|
'cancelled'
};

export const RequestWithdrawalComponentDialog = (props: {
  isOpen: boolean;
  closeModal: (withdrawal?: Withdrawal) => void;
}) => {
  const schema = yup
    .object({
      amount: yup.number().required().min(1000),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RequestWithdrawalFormType>({
    defaultValues: {} as any,
    resolver: yupResolver(schema as any),
  });

  const { setLoading } = useContext(LoadingContext);
  const { client: medusaClient } = useMedusa();

  const createWithdrawalRequest = async (data: RequestWithdrawalFormType) => {
    console.error(data);
    setLoading!(true);

    // create the deposit and dispose
    fetch(`${BASE_URL}store/withdrawals`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message, errors }: { message: string, errors: string[]  } = await response.json();
          throw new Error(message ?? first([errors]));
        }

        const { withdrawal }: { withdrawal: Withdrawal } =
          await response.json();
        // close dialog

        toast.warning("Withdrawal request submitted");

        reset();
        props.closeModal(withdrawal);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <Modal
        size={"sm"}
        isOpen={props.isOpen}
        onClose={() => {
          props.closeModal();
          reset();
        }}
      >
        <form onSubmit={handleSubmit(createWithdrawalRequest)}>
          <ModalContent>
            <>
              <ModalHeader className="flex flex-col gap-1">
                {"Request Withdrawal"}
              </ModalHeader>
              <ModalBody>
                <p>Your account would be credited within 24 hours</p>
                <div className="grid grid-cols-1 gap-4 mb-4">
                    <Input
                      type="text"
                      label="Amount"
                      {...register("amount", { required: true })}
                      errorMessage={errors.amount?.message}
                      isInvalid={!isNil(errors.amount?.message)}
                      color={errors.amount?.message ? "danger" : undefined}
                      startContent={<h4 className="font-bold text-sm">NGN</h4>}
                    />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="warning" type="submit">
                  Submit
                </Button>
              </ModalFooter>
            </>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};
