import { Link, Listbox, ListboxItem, Switch, User } from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { ReactNode, useContext, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
  LOCAL_STORAGE_PREFER_LINE_CHART_KEY,
} from "../../../../../utils";
import { DemoModeContext, LoadingContext } from "../../../../..";
import { BASE_URL } from "../../../../../utils/constants";
import { toast } from "react-toastify";
import { first } from "lodash";
import { useLogoutDeleteSession } from "../../../../../lookups/customer-trades";

export const MoreTileComponenets = () => {
  const navigate = useNavigate();

  const { demoMode, setDemoMode } = useContext(DemoModeContext);

  const [state, setState] = useState<{
    logoutSuccessful: boolean;
    preferLineChart: boolean;
  }>({
    logoutSuccessful: false,
    preferLineChart: localStorage.getItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY)
      ? true
      : false,
  });
  const { setLoading } = useContext(LoadingContext);
  const { logout } = useLogoutDeleteSession();

  const actions: {
    label: string;
    link?: string;
    description?: string;
    action?: (setState: (_: any) => any) => void;
    isDestructive?: boolean;
    leading?: ReactNode;
    ending?: ReactNode;
  }[] = [
    {
      label: "Line Chart",
      description: "Use a line chart instead of a Candle chart",
      leading: <FeatherIcon icon="activity" />,
      ending: (
        <Switch
          isSelected={state.preferLineChart}
          className="pointer-events-none"
        />
      ),
      action: (setState: (_: any) => any) => {
        setState({ ...state, preferLineChart: !state.preferLineChart });

        !state.preferLineChart
          ? localStorage.setItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY, "1")
          : localStorage.removeItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY);
      },
    },
    {
      label: "Demo Mode",
      description: "Practice Demo Trades",
      leading: <FeatherIcon icon="crosshair" />,
      ending: <Switch isSelected={demoMode} className="pointer-events-none" />,
      action: async (setState: (_: any) => any) => {
        // todo send to server

        try {
          setLoading!(true);
          if(!demoMode){
          const req = await fetch(`${BASE_URL}store/wallet/reset-demo`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                LOCAL_STORAGE_ACCESS_TOKEN_KEY
              )}`,
            },
          });

          if (!req.ok) {
            const { message, errors }: { message: string; errors: string[] } =
              await req.json();
            throw new Error(message ?? first([errors]));
          }
          if (req.status == 401) {
            // logout
    logout()
    .then(() => {
      localStorage.clear();
      window.location.pathname = "/";
    })
    .catch((e) => {
      toast.error("Failed to log you out");
    });
            return;
          }
        }

          setDemoMode!(!demoMode);
          !demoMode
            ? localStorage.setItem(LOCAL_STORAGE_DEMO_MODE_KEY, "1")
            : localStorage.removeItem(LOCAL_STORAGE_DEMO_MODE_KEY);
          toast.warning("Demo Configured");
        } catch (e) {
          toast.warning("Failed to configure demo mode");
        } finally {
          setLoading!(false);
        }
      },
    },
    {
      label: "Update Profile",
      link: "/home/update-profile",
      description: "Personal information, Settlement details",
      leading: <FeatherIcon icon="user" />,
    },
    {
      label: "Privacy Policy",
      link: "/public/privacy-policy",
      leading: <FeatherIcon icon="shield" />,
    },
    {
      label: "Terms of Use",
      link: "/public/terms-of-service",
      leading: <FeatherIcon icon="file-text" />,
    },
    {
      label: "Log out",
      isDestructive: true,
      leading: <FeatherIcon icon="log-out" />,
      action: (setState: (_: any) => any) => {
            logout()
            .then(() => {
              localStorage.clear();
              setState({ ...state, logoutSuccessful: true });
            })
            .catch((e) => {
              toast.error("Failed to log you out");
            });
      },
    },
  ];

  return (
    <>
      <Listbox
        items={actions}
        variant="flat"
        className="px-4 "
        aria-label="Listbox menu with descriptions"
      >
        {(a) => (
          <ListboxItem
            key={a.label}
            description={a.description}
            startContent={a.leading}
            endContent={a.ending}
            className={`${a.isDestructive ? "text-danger" : ""}`}
            color={`${a.isDestructive ? "danger" : "default"}`}
            onClick={() => (a.link ? navigate(a.link!) : a.action!(setState))}
          >
            {a.label}
          </ListboxItem>
        )}
      </Listbox>

      {state.logoutSuccessful && <Navigate to="/login" />}
    </>
  );
};
