import { Customer } from "@medusajs/medusa";
import { Button, Tab, Tabs } from "@nextui-org/react";
import { CustomerDetailHeaderComponent } from "./components/customer-detail-header-component";
import { CustomerTokenListComponent } from "./components/customer-tokens-list-component";
import { CustomerTransactionHistoryListComponent } from "./components/customer-transaction-history-list-component";
import { useLoaderData } from "react-router-dom";
import { CreateDepositFragment } from "./create-deposit-fragment";
import { useContext, useState } from "react";
import { RequestWithdrawalComponentDialog } from "./components/request-withdrawal-component";
import { CustomerDepositListComponent } from "./components/customer-deposit-list-component";
import { CustomerWithdrawalListComponent } from "./components/customer-withdrawal-list-component";
import { DemoModeContext } from "../../../..";
import { Chance } from "chance";
import { CustomerOpenTradesListComponent } from "../assets/components/customer-open-trades-list-component";

export const CustomerDetailFragment = (props: { onClose?: () => void }) => {
  const customer: Customer = useLoaderData() as Customer;
  const [isCreateDepositDialogOpen, setIsCreateDepositDialogOpen] =
    useState(false);

    const { demoMode } = useContext(DemoModeContext);

  const [isWithdrawalRequestDialogOpen, setIsWithdrawalRequestDialogOpen] =
    useState(false);

  return (
    <>
      <div
        className={`flex flex-col items-start ${
          isCreateDepositDialogOpen && "hidden"
        }`}
      >
        {/* <div style={{ marginTop: "56px" }}></div> */}

        <div className="mt-2 mb-2">
          <CustomerDetailHeaderComponent
            customer={customer}
            onClose={props.onClose}
          />
        </div>

          <div className="flex flex-col items-center self-center mt-2">
            <h4 className="text-small font-semibold leading-none text-default-600">
              Balance
            </h4>

            <p className="text-2xl font-semibold mb-2">
              {new Intl.NumberFormat("en-US", {
                currency: "NGN",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(demoMode ? ((customer as any).demoAccountBalance ?? 0):((customer as any).accountBalance ?? 0))
            }
            </p>

            <div className="grid grid-cols-2 gap-2">
              <Button
                color="warning"
                onClick={() => setIsCreateDepositDialogOpen(true)}
              >
                Deposit
              </Button>
              <Button
                // disabled={((customer as any).accountBalance ?? 0) <= 0}
                onClick={() => setIsWithdrawalRequestDialogOpen(true)}
              >
                Withdraw
              </Button>
            </div>
          </div>

        <Tabs
          key={"tokens"}
          size={"sm"}
          aria-label="Customer Detail Tabs"
          className="mt-6 mb-4 self-center"
        >
          <Tab key="tokens" title="Tokens" className="w-full">
            <CustomerTokenListComponent />
          </Tab>
          <Tab key="deposits" title="Deposits" className="w-full">
            <CustomerDepositListComponent />
          </Tab>
          <Tab key="withdrawals" title="Withdrawals" className="w-full">
            <CustomerWithdrawalListComponent />
          </Tab>
        <Tab key="open_trades" title="Trades" className="w-full">
          <CustomerOpenTradesListComponent  />
        </Tab>
        </Tabs>
      </div>

      {isCreateDepositDialogOpen && (
        <div className="w-full">
          <CreateDepositFragment
            closeModal={(_) => setIsCreateDepositDialogOpen(false)}
          ></CreateDepositFragment>
        </div>
      )}
      {isWithdrawalRequestDialogOpen && (
        <div className="w-full">
          <RequestWithdrawalComponentDialog
            isOpen={isWithdrawalRequestDialogOpen}
            closeModal={(_) => setIsWithdrawalRequestDialogOpen(false)}
          ></RequestWithdrawalComponentDialog>
        </div>
      )}
    </>
  );
};
