import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";

import { useLoaderData } from "react-router-dom";
import { Customer } from "@medusajs/medusa";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
// import FilePondPluginImageEditor from "../../scripts/filepond-plugin-image-editor"
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  getEditorDefaults,
  openEditor,
  plugin_crop,
  processImage,
  setPlugins,
} from "../../../../scripts/pintura";
import { LoadingContext, medusa } from "../../../..";
import {
  FormImage,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "../../../../utils";
import { prepareImages } from "../../../../utils/images";
import { toast } from "react-toastify";
import { MoreHeaderComponent } from "./components/header-component";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { first, isNil } from "lodash";
import { useBankList } from "../../../../lookups/banks";
import { BASE_URL } from "../../../../utils/constants";
import { useLogoutDeleteSession } from "../../../../lookups/customer-trades";

// Register the plugins
registerPlugin(
  /* FilePondPluginImageExifOrientation, FilePondPluginImagePreview, */
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize
);

setPlugins(plugin_crop);

export type PersonalInformationFormType = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  referralCode?: string;
  account_number?: string;
  account_name?: string;
  bank_code?: string;
  avatar?: FormImage[];
};

const CustomerWalletInitializerLoader = async (params: Record<string, any>) => {

  const { logout } = useLogoutDeleteSession();
  const req = await fetch(`${BASE_URL}store/wallet/init`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_ACCESS_TOKEN_KEY
      )}`,
      demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY) ? "yes" : "no",
    },
  });

  if (!req.ok) {
    const { message, errors }: { message: string, errors: string[]  } = await req.json();
    throw new Error(message ?? first([errors]));
  }
  if (req.status == 401) {
    // logout
    logout()
    .then(() => {
      localStorage.clear();
      window.location.pathname = "/";
    })
    .catch((e) => {
      toast.error("Failed to log you out");
    });
  }

  return {};
};

export const PersonalInformationFragmentLoader = async (
  params: Record<string, any>
) => {try{
    const { customer } = await medusa.customers.retrieve({
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_ACCESS_TOKEN_KEY
      )}`,
    })

    await CustomerWalletInitializerLoader(params);

    return customer;}
    catch(e) {
      window.location.pathname = '/'
      console.error(e);
    }
};

export const ProfileUpdateFragment = () => {
  const schema = yup
    .object({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      phone: yup.string(),
      email: yup.string(),
      account_number: yup.string().min(10).max(10),
      account_name: yup.string(),
      bank_code: yup.string(),
    })
    .required();

  const customer: Customer = useLoaderData() as Customer;

  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PersonalInformationFormType>({
    defaultValues: {
      email: customer.email,
      first_name: customer.first_name,
      referralCode: (customer as any).referralCode,
      last_name: customer.last_name,
      phone: customer.phone,
      avatar: customer.metadata?.avatar ?? [],
      prefix: customer.metadata?.prefix ?? "+234",
      account_number: customer.metadata?.account_number,
      account_name: customer.metadata?.account_name,
      bank_code: customer.metadata?.bank_code,
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { replace: replaceProfileImage, fields: avatarFields } = useFieldArray({
    control: control,
    name: "avatar",
  } as any);

  const imageEditorOptions = {
    // Maps legacy data objects to new imageState objects (optional)
    // legacyDataToImageState: legacyDataToImageState,

    // Used to create the editor (required)
    createEditor: openEditor,

    // Used for reading the image data. See JavaScript installation for details on the `imageReader` property (required)
    imageReader: [createDefaultImageReader],

    // Required when generating a preview thumbnail and/or output image
    imageWriter: [createDefaultImageWriter],

    // Used to create poster and output images, runs an invisible "headless" editor instance
    imageProcessor: processImage,

    // Pintura Image Editor options
    editorOptions: {
      // Pass the editor default configuration options
      ...getEditorDefaults(),

      // This will set a square crop aspect ratio
      // placeholder="1200 x 1600 (3:4) recommended, up to 10MB each"
      imageCropAspectRatio: 1,
    },
  };

  const { client: medusaClient } = useMedusa();
  const [state, setState] = useState({});
  const { setLoading } = useContext(LoadingContext);

  const [isBanksDropdownOpen, setIsBanksDropdownOpen] = useState(false);
  const {
    items: banks,
    hasMore: hasMoreBanks,
    isLoading,
    onLoadMore: onLoadMoreBanks,
  } = useBankList({ fetchDelay: 0 });

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreBanks,
    isEnabled: isBanksDropdownOpen,
    shouldUseLoader: false, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreBanks,
  });

  const updateCustomerProfileAction = async (
    data: PersonalInformationFormType
  ) => {
    setLoading!(true);

    let preppedImages: FormImage[] = [];

    try {
      preppedImages = await prepareImages(medusaClient, data.avatar ?? []);
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the thumbnail.";

      toast.error((error as any) ?? errorMessage);
      return;
    } finally {
      setLoading!(false);
    }

    medusaClient.customers
      .update(
        {
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          metadata: {
            ...customer.metadata,
            avatar: preppedImages,
            account_number: data.account_number,
            account_name: data.account_name,
            bank_code: data.bank_code,
          },
        },
        {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        }
      )
      .then((res) => {
        // show toaster
        toast.success("Profile Updated");
      })
      .catch((err) => {
        // show toaster
        toast.error(err?.response?.data?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <div className="flex flex-col items-start">
        {/* <div style={{ marginTop: "56px" }}></div> */}

        <div className="mt-2 mb-2">
          <MoreHeaderComponent title="Update Profile" />
        </div>

        <form
          className="p-8"
          onSubmit={handleSubmit(updateCustomerProfileAction)}
        >
          <small className="text-default-500">Personal Information</small>

          <div
            className="w-full bg-slate-200 mt-2 mb-4"
            style={{ height: 1 }}
          ></div>

          <div className="text-center m-auto w-40 h-40 relative mb-4">
            {useMemo(
              () => (
                <FilePond
                  filePosterMaxHeight={200}
                  maxFileSize={"3072KB"}
                  stylePanelLayout={"compact circle"}
                  styleLoadIndicatorPosition="center bottom"
                  styleProgressIndicatorPosition="right bottom"
                  styleButtonRemoveItemPosition="left bottom"
                  styleButtonProcessItemPosition="right bottom"
                  acceptedFileTypes={[
                    "image/gif",
                    "image/jpeg",
                    "image/png",
                    "image/webp",
                  ]}
                  server={{
                    load: (source, load, error, progress, abort, headers) => {
                      var myRequest = new Request(source);
                      fetch(myRequest)
                        .then(function (response) {
                          response.blob().then(function (myBlob) {
                            load(myBlob);
                          });
                        })
                        .catch(error);
                    },
                  }}
                  files={avatarFields.map((field) => ({
                    options: {
                      type: "local",
                    },
                    source: (field as any).url,
                  }))}
                  // imageEditEditor={createEditor}
                  imageEditor={imageEditorOptions}
                  // imageEditorInstantEdit={true}
                  onaddfile={(_, file) => {
                    if (_) return console.error(_);
                    replaceProfileImage([
                      {
                        url: URL.createObjectURL(file.file),
                        name: file.filename,
                        size: file.fileSize,
                        nativeFile: file.file as any,
                      },
                    ]);
                  }}
                  allowMultiple={false}
                  name="files" /* sets the file input name, it's filepond by default */
                  labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
                />
              ),
              []
            )}
          </div>

          <div className="mb-4">
            <Input
              type="email"
              label="Email"
              readOnly
              disabled
              {...register("email", { required: true })}
            />
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <Input
              type="text"
              label="First Name"
              {...register("first_name", { required: true })}
              errorMessage={errors.first_name?.message}
              isInvalid={!isNil(errors.first_name?.message)}
              color={errors.first_name?.message ? "danger" : undefined}
            />

            <Input
              type="text"
              label="Last Name"
              {...register("last_name", { required: true })}
              errorMessage={errors.last_name?.message}
              isInvalid={!isNil(errors.last_name?.message)}
              color={errors.last_name?.message ? "danger" : undefined}
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              label="Phone"
              {...register("phone", { required: true })}
              defaultValue={"+234"}
              errorMessage={errors.phone?.message}
              isInvalid={!isNil(errors.phone?.message)}
              color={errors.phone?.message ? "danger" : undefined}
            />
          </div>

          <small className="text-default-500">Settlement Information</small>

          <div
            className="w-full bg-slate-200 mt-2 mb-4"
            style={{ height: 1 }}
          ></div>
          <div className="mb-4">
            <Input
              type="text"
              label="Acccount Number"
              {...register("account_number", {})}
              errorMessage={errors.account_number?.message}
              isInvalid={!isNil(errors.account_number?.message)}
              color={errors.account_number?.message ? "danger" : undefined}
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              label="Account Name"
              {...register("account_name", {})}
              errorMessage={errors.account_name?.message}
              isInvalid={!isNil(errors.account_name?.message)}
              color={errors.account_name?.message ? "danger" : undefined}
            />
          </div>
          <div className="mb-4">
            <Select
              isLoading={isLoading}
              items={banks}
              label="Pick a Bank"
              {...register("bank_code", {})}
              placeholder="Select a Bank"
              defaultSelectedKeys={
                getValues("bank_code") ? [getValues("bank_code") as string] : []
              }
              scrollRef={scrollerRef}
              selectionMode="single"
              onOpenChange={setIsBanksDropdownOpen}
            >
              {(item) => (
                <SelectItem key={item.code} className="capitalize">
                  {item.name}
                </SelectItem>
              )}
            </Select>
          </div>

          <Button type="submit" fullWidth={true} color="warning">
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};
