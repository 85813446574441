import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { Store } from "@medusajs/medusa";
import { useRouteLoaderData } from "react-router-dom";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { Chance } from "chance";
import "filepond/dist/filepond.min.css";
// import FilePondPluginImageEditor from "../../scripts/filepond-plugin-image-editor"
import {
  Button,
  Card,
  CardBody,
  Input,
  Tab,
  Tabs,
  Textarea,
} from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { first, isNil } from "lodash";
import { toast } from "react-toastify";
import { DemoModeContext, LoadingContext } from "../../../..";
import { useBankList } from "../../../../lookups/banks";
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  getEditorDefaults,
  openEditor,
  plugin_crop,
  processImage,
  setPlugins,
} from "../../../../scripts/pintura";
import {
  FormImage,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_LAST_GENERATED_UNUSED_NARRATION,
} from "../../../../utils";
import { BASE_URL } from "../../../../utils/constants";
import { prepareImages } from "../../../../utils/images";
import { MoreHeaderComponent } from "../more/components/header-component";

// Register the plugins
registerPlugin(
  /* FilePondPluginImageExifOrientation, FilePondPluginImagePreview, */
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize
);

setPlugins(plugin_crop);

export type CreateDepositFormType = {
  amount: number;
  currency: "NGN" | "USDT";
  narration: string;
  avatar?: FormImage[];
};

export enum DepositStatus {
  PENDING = "pending",
  APPROVED = "approved",
  FAILED = "failed",
}

export type Deposit = {
  status: DepositStatus;
  id: string;
  amount: number;
  currency: "NGN" | "USDT";
  proof: Record<string, any>;
  created_at: string;
};

export const CreateDepositFragment = (props: {
  closeModal: (deposit: Deposit) => void;
}) => {
  const newNarration = new Chance().string({
    alpha: true,
    numeric: true,
    casing: "upper",
    length: 9,
    symbols: false,
  });
  let lastGeneratedNarration = localStorage.getItem(
    LOCAL_STORAGE_LAST_GENERATED_UNUSED_NARRATION
  );

  if (!lastGeneratedNarration) {
    lastGeneratedNarration = newNarration;
    localStorage.setItem(
      LOCAL_STORAGE_LAST_GENERATED_UNUSED_NARRATION,
      newNarration
    );
  }
  const schema = yup
    .object({
      amount: yup.number().required(),
      currency: yup.string().oneOf(["NGN", "USDT"]),
      narration: yup.string().required(),
    })
    .required();

  const { store } = useRouteLoaderData("root") as { store: Store };

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CreateDepositFormType>({
    defaultValues: {
      amount: 0,
      avatar: [],
      currency: "NGN",
      narration: lastGeneratedNarration,
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { replace: replaceAttachmentImage, fields: avatarFields } =
    useFieldArray({
      control: control,
      name: "avatar",
    } as any);

  const imageEditorOptions = {
    // Maps legacy data objects to new imageState objects (optional)
    // legacyDataToImageState: legacyDataToImageState,

    // Used to create the editor (required)
    createEditor: openEditor,

    // Used for reading the image data. See JavaScript installation for details on the `imageReader` property (required)
    imageReader: [createDefaultImageReader],

    // Required when generating a preview thumbnail and/or output image
    imageWriter: [createDefaultImageWriter],

    // Used to create poster and output images, runs an invisible "headless" editor instance
    imageProcessor: processImage,

    // Pintura Image Editor options
    editorOptions: {
      // Pass the editor default configuration options
      ...getEditorDefaults(),

      // This will set a square crop aspect ratio
      // placeholder="1200 x 1600 (3:4) recommended, up to 10MB each"
      imageCropAspectRatio: 1,
    },
  };

  const { client: medusaClient } = useMedusa();
  const [state, setState] = useState({});
  const { setLoading } = useContext(LoadingContext);
  const { demoMode } = useContext(DemoModeContext);


  const createDepositAction = async (data: CreateDepositFormType) => {
    setLoading!(true);

    let preppedImages: FormImage[] = [];

    try {
      preppedImages = await prepareImages(medusaClient, data.avatar ?? []);
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the thumbnail.";

      toast.error((error as any) ?? errorMessage);
      return;
    } finally {
      setLoading!(false);
    }

    // create the deposit and dispose
    fetch(`${BASE_URL}store/deposits`, {
      method: "POST",
      body: JSON.stringify({
        amount: data.amount,
        proof: preppedImages[0],
        currency: data.currency,
        narration: data.narration,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message, errors }: { message: string; errors: string[] } =
            await response.json();
          throw new Error(message ?? first([errors]));
        }

        const { deposit }: { deposit: Deposit } = await response.json();
        // close dialog

        toast.warning(
          demoMode
            ? "Demo Mode, your account has been funded"
            : "Your account would be credited after successful confirmation"
        );

        props.closeModal(deposit);
        reset();
      })
      .catch((err) => {
        // show toaster
        toast.error(err.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <div className="flex flex-col items-start">
        {/* <div style={{ marginTop: "56px" }}></div> */}

        <div className="mt-2 mb-2">
          <MoreHeaderComponent
            title="Create Deposit"
            onClose={props.closeModal}
          />
        </div>
        <div className="px-8 w-full">
          <Card className="mt-4 w-full" style={{ width: "100%" }}>
            <CardBody className="overflow-visible py-2 flex-rows justify-center items-center  px-6">
              <Tabs
                key={"ngn"}
                onSelectionChange={(k) => {
                  setValue("currency", (k as string).toUpperCase() as any);
                }}
                size={"sm"}
                aria-label="Deposit Currency"
                className="mt-6 mb-4 self-center"
              >
                <Tab key="ngn" title="NGN" className="w-full">
                  <div className="flex flex-col items-start">
                    <Input
                      type="text"
                      label="Bank"
                      className="mb-2 w-full"
                      disabled
                      value={store.metadata!["ngn_account_bank"] as any}
                    />
                    <Input
                      type="text"
                      label="Account Number"
                      className="mb-2"
                      disabled
                      endContent={
                        <Button
                          isIconOnly
                          size="sm"
                          color="warning"
                          variant="faded"
                          aria-label="Copy account number"
                          onClick={async () => {
                            await navigator.clipboard.writeText(
                              store.metadata!["ngn_account_number"] as string
                            );

                            toast.success(`Copied`);
                          }}
                        >
                          <FeatherIcon icon="copy" size={"16px"} />
                        </Button>
                      }
                      value={store.metadata!["ngn_account_number"] as any}
                    />
                  </div>
                </Tab>
                <Tab key="usdt" title="USDT" className="w-full">
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <img
                      src={store.metadata!["usdt_deposit_qr"] as any}
                      style={{ width: 256, height: "auto" }}
                    />
                    <div className="flex flex-col items-start">
                      <Input
                        type="text"
                        label="Network"
                        className="mb-2"
                        disabled
                        value={store.metadata!["usdt_deposit_network"] as any}
                      />

                      <Textarea
                        label="Address"
                        disabled
                        value={store.metadata!["usdt_deposit_address"] as any}
                        endContent={
                          <Button
                            isIconOnly
                            size="sm"
                            color="warning"
                            variant="faded"
                            aria-label="Copy address"
                            onClick={async () => {
                              await navigator.clipboard.writeText(
                                store.metadata![
                                  "usdt_deposit_address"
                                ] as string
                              );

                              toast.success(`Copied`);
                            }}
                          >
                            <FeatherIcon icon="copy" size={"16px"} />
                          </Button>
                        }
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </CardBody>
          </Card>
        </div>
        <form
          className="p-8 w-full"
          onSubmit={handleSubmit(createDepositAction)}
        >
          <small className="text-default-500">Receipt Information</small>

          <div
            className="w-full bg-slate-200 mt-2 mb-4"
            style={{ height: 1 }}
          ></div>

          {useMemo(
            () => (
              <FilePond
                filePosterMaxHeight={200}
                maxFileSize={"3072KB"}
                // stylePanelLayout={"compact circle"}
                styleLoadIndicatorPosition="center bottom"
                styleProgressIndicatorPosition="right bottom"
                styleButtonRemoveItemPosition="left bottom"
                styleButtonProcessItemPosition="right bottom"
                acceptedFileTypes={[
                  "image/gif",
                  "image/jpeg",
                  "image/png",
                  "image/webp",
                ]}
                server={{
                  load: (source, load, error, progress, abort, headers) => {
                    var myRequest = new Request(source);
                    fetch(myRequest)
                      .then(function (response) {
                        response.blob().then(function (myBlob) {
                          load(myBlob);
                        });
                      })
                      .catch(error);
                  },
                }}
                files={avatarFields.map((field) => ({
                  options: {
                    type: "local",
                  },
                  source: (field as any).url,
                }))}
                // imageEditEditor={createEditor}
                imageEditor={imageEditorOptions}
                maxFiles={1}
                required
                // imageEditorInstantEdit={true}
                onaddfile={(_, file) => {
                  if (_) return console.error(_);
                  replaceAttachmentImage([
                    {
                      url: URL.createObjectURL(file.file),
                      name: file.filename,
                      size: file.fileSize,
                      nativeFile: file.file as any,
                    },
                  ]);
                }}
                allowMultiple={false}
                name="files" /* sets the file input name, it's filepond by default */
                labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
              />
            ),
            []
          )}

          <div className="my-4">
            <Input
              type="text"
              readOnly
              label="Narration"
              {...register("narration", { required: true })}
              endContent={
                <Button
                  isIconOnly
                  size="sm"
                  color="warning"
                  variant="faded"
                  aria-label="Copy narration"
                  onClick={async () => {
                    await navigator.clipboard.writeText(lastGeneratedNarration!);
                    toast.success(`Copied`);
                  }}
                >
                  <FeatherIcon icon="copy" size={"16px"} />
                </Button>
              }
              errorMessage={
                errors.narration?.message ??
                "Provide this narration in your transaction so we know it's yours"
              }
              isInvalid={!isNil(errors.narration?.message)}
              color={errors.narration?.message ? "danger" : undefined}
            />
          </div>

          <div className="my-4">
            <Input
              type="text"
              label="Amount"
              {...register("amount", { required: true })}
              errorMessage={errors.amount?.message}
              isInvalid={!isNil(errors.amount?.message)}
              color={errors.amount?.message ? "danger" : undefined}
            />
          </div>

          <Button type="submit" fullWidth={true} color="warning">
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};
