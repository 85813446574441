import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { LoadingContext } from "../../..";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { isNil } from "lodash";
import { toast } from "react-toastify";

export const ForgotPasswordDialog = (props: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const schema = yup
    .object({
      email: yup.string().required().email(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: null,
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { setLoading } = useContext(LoadingContext);
  const { client: medusaClient } = useMedusa();

  const requestPasswordReset = async (data: { email: string }) => {
    console.error(data);
    setLoading!(true);
    medusaClient.customers
      .generatePasswordToken(data)
      .then((res) => {
        toast.success(
          "if you have an account with us, we've sent you an email with further instructions"
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? "An error occured");
      })
      .finally(() => {
        reset();
        setLoading!(false);
        props.closeModal();
      });
  };

  return (
    <>
      <Modal
        size={"sm"}
        isOpen={props.isOpen}
        onClose={() => props.closeModal()}
      >
                <form onSubmit={handleSubmit(requestPasswordReset)}>

        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Reset your password
              </ModalHeader>
              <ModalBody>
                <p>
                  Enter the email address associated with your account and we'll
                  send you a link to reset your password.
                </p>

                  <div className="mb-4">
                    <Input
                      type="email"
                      label="Email"
                      placeholder="Enter your email"
                      {...register("email", { required: true })}
                      errorMessage={errors.email?.message}
                      isInvalid={!isNil(errors.email?.message)}
                      color={errors.email?.message ? "danger" : undefined}
                    />
                  </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Return to login
                </Button>
                <Button color="warning" type="submit">
                  Continue
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
        </form>
      </Modal>
    </>
  );
};
