import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcon from "feather-icons-react";
import { isNil } from "lodash";
import { useMedusa } from "medusa-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { LoadingContext } from "../..";
import { Button, Input, Link } from "@nextui-org/react";
import { ToastContainer, toast } from 'react-toastify';

export type CreateAccountFormType = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone?: string;
  prefix?: string | null;
  referredByCode?: string | null;
};
const CreateAccountPage = () => {
  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required(),
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      phone: yup.string().optional(),
      referredByCode: yup
        .string()
        .nullable()
        .optional()
        .transform((curr, orig) => (orig === "" ? null : curr)),
      prefix: yup
        .string()
        .nullable()
        .optional()
        .transform((curr, orig) => (orig === "" ? null : curr)),
    })
    .required();

    const [searchParams, setSearchParams] = useSearchParams();
  
    const referralCode = searchParams.get("code");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAccountFormType>({
    defaultValues: { prefix: "+234", referredByCode: referralCode },
    resolver: yupResolver(schema),
  });

  const { client: medusaClient } = useMedusa();

  const [state, setState] = useState({
    registerSuccessful: false,
    obscurePass: true,
  });

  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();


  const validateRegistration = async (data: CreateAccountFormType) => {
    setState({ ...state });
    setLoading!(true);
    delete data.prefix;
    medusaClient.customers
      .create(data)
      .then((res) => {
        toast.success("Welcome to Autrid");
        setState({ ...state, registerSuccessful: true });
        setLoading!(false);
      })
      .catch((err) => {
        toast.error(  err?.response?.data?.message ?? 'An error occured');
        setState({ ...state, registerSuccessful: false });
        setLoading!(false);
      });
  };

  return (
    <>
      <section className="w-full bg-white dark:bg-darkblack-500">
        <div className="flex flex-col lg:flex-row justify-between min-h-screen">
          {/* <!-- Left --> */}
          <div className="px-5 xl:pl-12 pt-10 m-auto">
            <header className="flex justify-center flex-row">
              {/* <!--              page-title--> */}
              <a href="https://autrid.com">
                <div className="bg-white rounded-md">
                  <img src="/autrid.png" className="w-[136px] px-4 py-2" />
                </div>
              </a>
            </header>
            <div className="max-w-[450px] m-auto pt-16 pb-16">
              <header className="text-center mb-8">
                <h2 className="text-bgray-900  text-4xl font-semibold font-poppins mb-2">
                  Register on Autrid
                </h2>
                <p className="font-urbanis text-base font-medium text-bgray-600 ">
                  Trade and Grow
                </p>
              </header>
              <form onSubmit={handleSubmit(validateRegistration)}>
                <div className="mb-4">
                  <Input
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    {...register("email", { required: true })}
                    errorMessage={errors.email?.message}
                    isInvalid={!isNil(errors.email?.message)}
                    color={errors.email?.message ? "danger" : undefined}
                  />
                </div>

                <div className="mb-6">
                  <Input
                    label="Password"
                    fullWidth={true}
                    placeholder="Enter a password"
                    errorMessage={errors.password?.message}
                    isInvalid={!isNil(errors.password?.message)}
                    color={errors.password?.message ? "danger" : undefined}
                    endContent={
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() =>
                          setState({
                            ...state,
                            obscurePass: !state.obscurePass,
                          })
                        }
                      >
                        {state.obscurePass ? (
                          <FeatherIcon icon="eye-off"></FeatherIcon>
                        ) : (
                          <FeatherIcon icon="eye"></FeatherIcon>
                        )}
                      </button>
                    }
                    type={state.obscurePass ? "password" : "text"}
                    {...register("password", { required: true, minLength: 4 })}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                  <Input
                    type="text"
                    label="First Name"
                    {...register("first_name", { required: true })}
                    errorMessage={errors.first_name?.message}
                    isInvalid={!isNil(errors.first_name?.message)}
                    color={errors.first_name?.message ? "danger" : undefined}
                  />

                  <Input
                    type="text"
                    label="Last Name"
                    {...register("last_name", { required: true })}
                    errorMessage={errors.last_name?.message}
                    isInvalid={!isNil(errors.last_name?.message)}
                    color={errors.last_name?.message ? "danger" : undefined}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Phone"
                    {...register("phone", { required: true })}
                    defaultValue={"+234"}
                    errorMessage={errors.phone?.message}
                    isInvalid={!isNil(errors.phone?.message)}
                    color={errors.phone?.message ? "danger" : undefined}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Referral Code (Optional)"
                    {...register("referredByCode")}
                    errorMessage={errors.referredByCode?.message}
                    isInvalid={!isNil(errors.referredByCode?.message)}
                    color={
                      errors.referredByCode?.message ? "danger" : undefined
                    }
                  />
                </div>

                <Button type="submit" fullWidth={true} color="warning">
                  Sign Up
                </Button>

                <div className="mb-7">
                  <div>
                    <p className="text-bgray-600  text-center text-sm mt-6">
                      Already have an account?&nbsp;&nbsp;&nbsp;
                      <Link
                        href="#"
                        className="text-sm"
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
              <p className="text-bgray-600  text-center text-sm mt-6">
                @ 2024 Autrid Trading. All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>

      {state.registerSuccessful && <Navigate to="/login" replace={true} />}
    </>
  );
};

export default CreateAccountPage;
