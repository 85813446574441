import { Link } from "@nextui-org/react";
import { MoreHeaderComponent } from "./components/header-component";

export const TermsOfServiceComponent = () => {
  return (
    <div className="flex flex-col items-start">
      <div style={{ marginTop: "56px" }}></div>

      <div className="mt-2 mb-2">
        <MoreHeaderComponent title="Terms of Service" />
      </div>

      <div className="p-8">
        
      <h1 className="text-lg font-bold">Terms of Service</h1>
        <p>
          <strong>Effective Date:</strong> 1st January, 2024
        </p>
    <br/><h2><strong>1. Introduction</strong></h2>
    <p>Welcome to Autrid, a trading platform operated by Autrid Trading Group. By using our platform, you agree to comply with and be bound by the following Terms of Service (ToS). Please review these terms carefully before using our services.</p>

    <br/><h2><strong>2. User Registration</strong></h2>
    <ul>
        <li>To register, you must provide your full legal name, email, and phone number.</li>
        <li>You must agree to these ToS to use the platform.</li>
        <li>Users must be at least 18 years old to register.</li>
    </ul>

    <br/><h2><strong>3. User Responsibilities</strong></h2>
    <ul>
        <li>Users are responsible for maintaining the confidentiality of their account information.</li>
        <li>Users must not engage in any activity that violates applicable laws or regulations.</li>
        <li>Prohibited activities include, but are not limited to, illegal activities, spamming, harassment, and any activity that disrupts or interferes with the platform's functionality.</li>
    </ul>

    <br/><h2><strong>4. Service Description</strong></h2>
    <ul>
        <li>Autrid provides trading services for users.</li>
        <li>Autrid does not guarantee the availability, accuracy, or timeliness of trading services.</li>
        <li>Orders, trades, and executions are subject to market conditions and other factors beyond our control.</li>
    </ul>

    <br/><h2><strong>5. Account Security</strong></h2>
    <ul>
        <li>Users must protect their account information and notify Autrid immediately of any unauthorized use.</li>
        <li>Autrid reserves the right to suspend or terminate accounts if suspicious activity is detected.</li>
    </ul>

    <br/><h2><strong>6. Fees and Payments</strong></h2>
    <ul>
        <li>There are no fees for using the platform.</li>
        <li>Payment terms and conditions apply to the funding and withdrawal processes, which must comply with applicable laws and regulations.</li>
    </ul>

    <br/><h2><strong>7. Content and Intellectual Property</strong></h2>
    <p>The platform does not host user-generated content. Users must respect Autrid's intellectual property and trademarks.</p>

    <br/><h2><strong>8. Termination of Service</strong></h2>
    <ul>
        <li>Autrid reserves the right to terminate user accounts for abuse, such as submitting invalid information for funding accounts or attempting to access disallowed features.</li>
        <li>Illegal penetration testing is prohibited.</li>
        <li>Banned users and any future accounts they attempt to create will be permanently blacklisted.</li>
    </ul>

    <br/><h2><strong>9. Dispute Resolution</strong></h2>
    <ul>
        <li>Disputes are resolved through customer support.</li>
        <li>The laws of Nigeria govern these terms and any disputes arising under them.</li>
    </ul>

    <br/><h2><strong>10. Liability and Disclaimers</strong></h2>
    <ul>
        <li>Autrid is not liable for any direct, indirect, incidental, or consequential damages arising from the use of the platform.</li>
        <li>Users acknowledge that trading involves risks, and Autrid does not guarantee profits or the accuracy of trade execution.</li>
    </ul>

    <br/><h2><strong>11. Modifications to the ToS</strong></h2>
    <ul>
        <li>Autrid reserves the right to update these ToS quarterly or as needed.</li>
        <li>Users will be notified of any changes via email.</li>
    </ul>

    <br/><h2><strong>12. Contact Information</strong></h2>
    <p>For support or inquiries, users can contact us at 
    <Link href="mailto:support@autrid.com" className="">support@autrid.com</Link>.</p>

    <br/><h2><strong>13. Governing Law</strong></h2>
    <p>These ToS are governed by the laws of Nigeria.</p>

    <p>By using our platform, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them.</p>
      </div>
    </div>
  );
};
