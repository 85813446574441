import { Button, Select, SelectItem, User } from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import {Customer} from "@medusajs/medusa";
import { capitalize, first } from "lodash";
import { FormImage } from "../../../../../utils";

const pairs = [
  {
    key: "USD/XYZ",
    label: "USD to XYZ token",
  },
];

export const CustomerDetailHeaderComponent = (props: {customer: Customer, onClose?: ()=> void}) => {
  return (
    <>
      <div className="z-30 flex flex-rows justify-between pr-8 absolute top-0 border-b-2 border-slate-300 flex w-full bg-white rounded-none">
        
      <div
            className="flex-none bg-white rounded-md"
            style={{ width: "58px" }}
          >
            <img src="/autrid_borderless.png" />
          </div>

        <User
          name={`${props.customer.last_name??''} ${props.customer.first_name??''}`.trim()}
          description={capitalize((props.customer as any).level)}
          avatarProps={{
            src: 
            first(
              (props.customer.metadata?.avatar as FormImage[] | undefined) ?? []
            )?.url,
          }}
        />
      </div>
    </>
  );
};
