import { Tabs, Tab } from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { NavLink, Outlet } from "react-router-dom";
import { DemoModeContext, LoadingContext } from "../..";
import { useContext, useEffect } from "react";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "../../utils";
import { PUBLIC_VAPID_KEY } from "../../utils/constants";
import { useMedusa } from "medusa-react";
import { toast } from "react-toastify";

export default function HomePage() {
  const { demoMode, setDemoMode } = useContext(DemoModeContext);
  const { loading, setLoading } = useContext(LoadingContext);

  const { client: medusaClient } = useMedusa();
  setDemoMode!(localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY) == "1");

  useEffect(() => {
    const registerServiceWorker = async function registerServiceWorker() {
      if ("serviceWorker" in navigator) {
        try {
          const register = await navigator.serviceWorker.register(
            "/worker.js",
            {
              scope: "/",
            }
          );

          const vapid = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: PUBLIC_VAPID_KEY,
          });

          setLoading!(true);
          medusaClient.customers
            .update(
              {
                vapid,
              } as any,
              {
                Authorization: `Bearer ${localStorage.getItem(
                  LOCAL_STORAGE_ACCESS_TOKEN_KEY
                )}`,
              }
            )
            .then(async (response) => {
              console.info("notifications subscribed successfully");
            })
            .catch((error) => {
              console.error(error);
              console.error(error?.message ?? "An error occured");
            })
            .finally(() => {
              setLoading!(false);
            });
        } catch (e) {
          console.error(e);
        }
      }
    };

    registerServiceWorker();
  }, []);

  return (
    <>
      <div className="static overflow-y-scroll h-screen2">
        <div className="pb-40 mt-12">
          <Outlet />
        </div>
      </div>
      <div className="z-30 border-t-2 border-slate-300 grid grid-cols-4 fixed bottom-0 left-0 w-full bg-white rounded-none">
        {[
          {
            label: "Trade",
            icon: <FeatherIcon icon="bar-chart-2" />,
            path: "/home/trade",
          },
          {
            label: "Wallet",
            icon: <FeatherIcon icon="folder" />,
            path: "/home/wallet",
          },
          {
            label: "Settings",
            icon: <FeatherIcon icon="settings" />,
            path: "/home/more",
          },
          {
            label: "Notifications",
            icon: <FeatherIcon icon="bell" />,
            path: "/home/notifications",
          },
        ].map((item, index) => (
          <>
            <NavLink to={item.path!} key={index}>
              {({ isActive, isPending }) => (
                <div
                  key={index}
                  // onClick={() => item.action!({ setState } as any)}
                  className={`tab flex flex-col transition-all ${
                    isActive ? "bg-warning" : ""
                  }`}
                  data-tab="tab1"
                >
                  <div className="mx-auto w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100">
                    {item.icon}
                  </div>
                  <div>
                    <h4 className="text-center text-base font-bold text-bgray-900 overflow-hidden">
                      {item.label}
                    </h4>
                  </div>
                </div>
              )}
            </NavLink>
          </>
        ))}

        {demoMode && (
          <div className="flex w-full col-span-4 items-center bg-orange-500 z-30  ">
            <h4 className="mx-auto">DEMO MODE</h4>
          </div>
        )}
      </div>
    </>
  );
}
