import React from "react";
import { BASE_URL } from "../utils/constants";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils";
import { Deposit } from "../pages/home/fragments/customers/create-deposit-fragment";
import { debounce, first } from "lodash";
import { useLogoutDeleteSession } from "./customer-trades";
import { toast } from "react-toastify";

export type UseCustomerDepositsListProps = {
  /** Delay to wait before fetching more items */
};

export function useCustomerDepositsList({}: UseCustomerDepositsListProps) {
  const [items, setItems] = React.useState<Deposit[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const limit = 10; // Number of items per page, adjust as necessary

  const { logout } = useLogoutDeleteSession();
  const loadCustomerDeposits = async (currentOffset: number) => {
    if((!hasMore || isLoading)) return;
    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsLoading(true);

      const req = await fetch(
        `${BASE_URL}store/deposits?offset=${currentOffset}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LOCAL_STORAGE_ACCESS_TOKEN_KEY
            )}`,
          },
        }
      );

      if (!req.ok) {
        const { message, errors }: { message: string, errors: string[]  } = await req.json();
        throw new Error(message ?? first([errors]));
      }  if(req.status == 401) {
        // logout
    logout()
    .then(() => {
      localStorage.clear();
      window.location.pathname = "/";
    })
    .catch((e) => {
      toast.error("Failed to log you out");
    });
      }

      const {
        deposits,
        offset: _offset,
        limit: _limit,
        count: _count,
      }: {
        deposits: Deposit[];
        offset: number;
        limit: number;
        count: number;
      } = await req.json();

      setItems((prevItems) => [...prevItems, ...deposits]);
      setHasMore(
        !(_offset == 0 ? items.length >= _count! : _offset! + 1 >= _count!)
      );
    
    } catch (error: any) {
      if((error?.response as any)?.status == 401) {
        
    logout()
    .then(() => {
      localStorage.clear();
      window.location.pathname = "/";
    })
    .catch((e) => {
      toast.error("Failed to log you out");
    });
      }else
      if ((error as any).name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("There was an error with the fetch operation:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadCustomerDeposits(offset);
  }, []);

  const onLoadMore = debounce(() => {
    if((!hasMore || isLoading)) return;
    
    const newOffset = offset + limit;
    setOffset(newOffset);
    loadCustomerDeposits(newOffset);
  }, 300);

  return {
    items,
    hasMore,
    isLoading,
    onLoadMore,
  };
}
