import { Listbox, ListboxItem, Spinner, User } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { first } from "lodash";
import { useNavigate } from "react-router-dom";
import { useNotificationsList } from "../../../../lookups/notifications";
import { ListboxWrapper } from "../../../../components/list-box-component";
import { MoreHeaderComponent } from "./components/header-component";
import { DateTime } from "luxon";

export const NotificationsListFragment = (props: {}) => {
  const {
    items: notifications,
    hasMore: hasMoreNotifications,
    isLoading,
    onLoadMore: onLoadMoreNotifications,
  } = useNotificationsList({});
  const navigate = useNavigate();

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreNotifications,

    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreNotifications,
  });

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className="mt-2 mb-2">
        <MoreHeaderComponent title="Notifications" />
      </div>
      <div className={`grow px-2 w-full`}>
        <ListboxWrapper className="">
          <Listbox
            classNames={{
              base: "",
              list: "page-listbox-2 overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            defaultSelectedKeys={["1"]}
            items={notifications}
            label="Notifications"
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
            topContent={isLoading ? <Spinner size="sm" /> : undefined}
            selectionMode="none"
            variant="flat"
          >
            {(item) => (
              <ListboxItem key={item.id} textValue={item.id}>
              <div className="flex flex-col items-start">
                <p className="text-md font-semibold">{item.title}</p>
                {item.content && (
                  <small className="text-slate-600 text-sm" style={{whiteSpace: 'normal'}}>{item.content}</small>
                )}
                {item.image && item.image.url && <img src={item.image.url} />}
                <h5 className="text-small tracking-tight text-default-400">
                  {DateTime.fromISO(item.created_at).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </h5>
              </div>


                <div
                  className="w-full bg-slate-200 mt-2"
                  style={{ height: 1 }}
                ></div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
