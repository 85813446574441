import {
    CircularProgress,
    Modal,
    ModalBody,
    ModalContent
} from "@nextui-org/react";
import React from "react";

type LoadingDialogProps = {
  isOpen: boolean;
};
type LoadingDialogState = {};

class LoadingDialog extends React.Component<
  LoadingDialogProps,
  LoadingDialogState
> {
  constructor(props: LoadingDialogProps) {
    super(props);
    this.state = {};
  }
  render(): React.ReactNode {
    return (
      <Modal size={"md"} isOpen={this.props.isOpen}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
                <CircularProgress aria-label="Loading..." />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    );
  }
}

export { LoadingDialog };

