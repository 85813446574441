import { Chip, Listbox, ListboxItem, Spinner } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { DateTime } from "luxon";
import { useCustomerTransactionList } from "../../../../../lookups/customer-transactions";
import { ListboxWrapper } from "../../../../../components/list-box-component";
import { useCustomerDepositsList } from "../../../../../lookups/customer-deposits";
import { DepositStatus } from "../create-deposit-fragment";

export const CustomerDepositListComponent = (props: {}) => {
  const {
    items: deposits,
    hasMore: hasMoreDeposits,
    isLoading,
    onLoadMore: onLoadMoreDeposits,
  } = useCustomerDepositsList({});

  const statusColorMap = {
    pending: "warning",
    approved: "success",
    failed: "danger",
  };

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreDeposits,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreDeposits,
  });

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */} 

      <div className={`grow px-2 w-full`}>
        <ListboxWrapper className={""}>
          <Listbox
            classNames={{
              base: "",
              list: "overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            defaultSelectedKeys={["1"]}
            items={deposits}
            topContent={isLoading ? <Spinner size="sm" />: undefined}
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
            label="Deposits"
            selectionMode="none"
            variant="flat"
          >
            {(item) => (
              <ListboxItem key={item.id} textValue={item.id}>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col gap-1 items-start justify-center">
                    <h4
                      className={`text-small font-semibold leading-none text-green-500`}
                    >
                      
                      {new Intl.NumberFormat("en-US", {
                        currency: "NGN",
                        style: "currency",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        item.amount
                      )}
                    </h4>
                    <h5 className="text-small tracking-tight text-default-400">
                      {DateTime.fromISO(item.created_at).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )}
                    </h5>
                  </div>

                  {
                    <Chip
                      className="capitalize"
                      color={statusColorMap[item.status] as any}
                      size="sm"
                      variant="flat"
                    >
                      {item.status}
                    </Chip>
                  }
                </div>
                <div
                  className="w-full bg-slate-200"
                  style={{ height: 1 }}
                ></div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
