import {
  Avatar,
  Link,
  Listbox,
  ListboxItem,
  Spinner,
  User,
} from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { first } from "lodash";
import { ListboxWrapper } from "../../../../../components/list-box-component";
import { useCustomerAssetList } from "../../../../../lookups/customer-assets";
import {
  FormImage,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "../../../../../utils";
import { useCustomerTradeList } from "../../../../../lookups/customer-trades";
import { useContext } from "react";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../../..";
import { BASE_URL } from "../../../../../utils/constants";
import { prepareImages } from "../../../../../utils/images";
import { Deposit } from "../../customers/create-deposit-fragment";
import { DateTime } from "luxon";

export const CustomerOpenTradesListComponent = (props: { symbol?: string }) => {
  const {
    items: trades,
    hasMore: hasMoreTrades,
    isLoading,
    onLoadMore: onLoadMoreTrades,
    refresh,
  } = useCustomerTradeList({ symbol: props.symbol });

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreTrades,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreTrades,
  });

  const { setLoading } = useContext(LoadingContext);
  const cancelTrade = async (id: number) => {
    setLoading!(true);

    // create the deposit and dispose
    fetch(`${BASE_URL}store/wallet/trades/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
        demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY) ? "yes" : "no",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message, errors }: { message: string; errors: string[] } =
            await response.json();
          throw new Error(message ?? first([errors]));
        }

        const { deposit }: { deposit: Deposit } = await response.json();
        // close dialog

        toast.warning("Trade cancelled");
        // remove trade from list
        refresh();
      })
      .catch((err) => {
        // show toaster
        toast.error(err.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };
  return (
    <div className="flex flex-col items-start">
      <div className={`grow w-full`}>
        <ListboxWrapper className="">
          <Listbox
            classNames={{
              base: "",
              list: "page-listbox-2 overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            items={trades}
            label="Open Trades"
            topContent={isLoading ? <Spinner size="sm" /> : undefined}
            selectionMode="none"
            variant="flat"
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
          >
            {(item) => (
              <ListboxItem key={item.id} textValue={item.symbol}>
                <div className="flex flex-rows items-center">
                  {item.asset.metadata.avatar &&
                    item.asset.metadata.avatar[0] && (
                      <Avatar
                        size="md"
                        src={item.asset.metadata.avatar[0].url}
                      />
                    )}
                  <div className="ml-2 flex flex-col items-start">
                    <p className="text-md font-semibold">
                      {item.asset.symbol}
                      <span
                        className={`text-sm ${
                          item.type == "sell"
                            ? "text-red-500"
                            : "text-green-400"
                        }`}
                      >
                        {item.type}
                      </span>
                    </p>
                  </div>

                  <div className="grow"></div>

                  {/* put the amount in circulation here */}

                  <div className="flex flex-col items-end">
                    <p className="text-md font-semibold">
                      {item.type == "sell"
                        ? `${item.amount} ${item.asset.symbol}`
                        : new Intl.NumberFormat("en-US", {
                            currency: "NGN",
                            style: "currency",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.amount)}{" "}
                    </p>
                    {item.type == "buy" && item.unit_price > 0 && (
                      <h5 className="text-small tracking-tight text-default-400">
                        ≈
                        {new Intl.NumberFormat("en-US", {
                          currency: "NGN",
                          style: "currency",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                          .format(item.amount / item.unit_price)
                          .replace("NGN", item.symbol)}
                      </h5>
                    )}
                    {item.type == "sell" && item.unit_price > 0 && (
                      <h5 className="text-small tracking-tight text-default-400">
                        ≈
                        {new Intl.NumberFormat("en-US", {
                          currency: "NGN",
                          style: "currency",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                          .format(item.amount * item.unit_price)
                          }
                      </h5>
                    )}
                  </div>
                </div>
                {item.category == "limit" && (
                  <p className="text-slate-600 text-sm">
                    <span>
                      Limit:{" "}
                      {item.limit
                        ? new Intl.NumberFormat("en-US", {
                            currency: "NGN",
                            style: "currency",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.limit)
                        : "N/A"}
                      &nbsp;&nbsp;
                    </span>

                    {item.type == "sell" && (
                      <span>
                        TP:{" "}
                        {item.tp
                          ? new Intl.NumberFormat("en-US", {
                              currency: "NGN",
                              style: "currency",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(item.tp)
                          : "N/A"}
                        &nbsp;&nbsp;
                      </span>
                    )}

                    {item.type == "sell" && (
                      <span>
                        SL:{" "}
                        {item.sl
                          ? new Intl.NumberFormat("en-US", {
                              currency: "NGN",
                              style: "currency",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(item.sl)
                          : "N/A"}
                        &nbsp;&nbsp;
                      </span>
                    )}
                  </p>
                )}

                <div className="flex flex-rows items-end self-end">
                  <h5 className="text-small tracking-tight text-default-400">
                    Placed{" "}
                    {DateTime.fromISO(item.created_at).toLocaleString(
                      DateTime.DATETIME_SHORT
                    )}
                  </h5>
                  {item.executed ? (
                    <></>
                  ) : (
                    <Link
                      href="#"
                      className="text-sm text-red-500 ml-2"
                      onClick={() => cancelTrade(item.id)}
                    >
                      Cancel
                    </Link>
                  )}
                </div>
                <div
                  className="w-full bg-slate-200 mt-2"
                  style={{ height: 1 }}
                ></div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
