import React from "react";
import { BASE_URL } from "../utils/constants";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "../utils";
import { debounce, first } from "lodash";
import { useLogoutDeleteSession } from "./customer-trades";
import { toast } from "react-toastify";
export type Asset = {
  status: "published" | "draft";
  id: string;
  symbol: string;
  level: "standard" | "pro" | "vip" | "vvip";
  circulationAmount?: number;
  currentPrice?: number;
  metadata: Record<string, any>;
} & {
  balance?: number;
};

export type UseCustomerAssetListProps = {
  /** Delay to wait before fetching more items */
};

export function useCustomerAssetList({}: UseCustomerAssetListProps) {
  const [items, setItems] = React.useState<Asset[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const limit = 10; // Number of items per page, adjust as necessary
  let q: string;

  const { logout } = useLogoutDeleteSession();
  const loadCustomerAssets = async (currentOffset: number, forceFetch = false) => {
    if(!forceFetch && (!hasMore || isLoading)) return;
    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsLoading(true);

      const _q = (() => q)();
      const req = await fetch(
        `${BASE_URL}store/wallet/assets?offset=${(()=>currentOffset)()}&limit=${(()=>limit)()}${_q ? `&q=${_q}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LOCAL_STORAGE_ACCESS_TOKEN_KEY
            )}`,
            demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY)
              ? "yes"
              : "no",
          },
        }
      );

      if (!req.ok) {
        const { message, errors }: { message: string; errors: string[] } =
          await req.json();
        throw new Error(message ?? first([errors]));
      }
      if (req.status == 401) {
        // logout
        logout()
          .then(() => {
            localStorage.clear();
            window.location.pathname = "/";
          })
          .catch((e) => {
            toast.error("Failed to log you out");
          });
      }

      const {
        assets,
        offset: _offset,
        limit: _limit,
        count: _count,
      }: {
        assets: Asset[];
        offset: number;
        limit: number;
        count: number;
      } = await req.json();

      setItems((prevItems) => [...prevItems, ...assets]);
      setHasMore(
        !(_offset == 0 ? items.length >= _count! : _offset! + 1 >= _count!)
      );
    } catch (error: any) {
      if ((error?.response as any)?.status == 401) {
        logout()
          .then(() => {
            localStorage.clear();
            window.location.pathname = "/";
          })
          .catch((e) => {
            toast.error("Failed to log you out");
          });
      } else if ((error as any).name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("There was an error with the fetch operation:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadCustomerAssets(offset);
  }, []);

  const onLoadMore = debounce(() => {
    if((!hasMore || isLoading)) return;

    const newOffset = offset + limit;
    setOffset(newOffset);
    loadCustomerAssets(newOffset);
  }, 300);

  const fireSearch = debounce(() => {
    setOffset(0);
    setItems([]);
    loadCustomerAssets(0, true);;
  }, 300);

  return {
    items,
    hasMore,
    isLoading,
    onLoadMore,
    setQ: (_q: string) => {
      q = _q;
      onLoadMore.cancel();
      fireSearch.cancel();
      fireSearch();
    },
  };
}
